import React, { Fragment, useEffect, useRef, useState } from 'react';
// import React, { useRef, useCallback, useState } from 'react';
import ButtonCustom from './ButtonCustom';
import Webcam from "react-webcam";
import {RootContext} from '../../Home';
import '../../assets/sass/web-camera.scss';

const WebCamera = (props) => {
	const webcamRef = useRef(null);
	const [imgSrc, setImgSrc] = useState(null);
    // const [isReset, setIsReset] = useState(false);

	// const capture = useCallback(() => {
	// 	const imageSrc = webcamRef.current.getScreenshot();
	// 	// setImgSrc(imageSrc);
	// 	// console.log(imageSrc)
	// 	// props.onScreenshot(imageSrc)
	// }, []);

    useEffect(() => {
        // console.log(props.submitCount);
    })

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        // setIsReset(false);
        props.onScreenshot(imageSrc);
    }

    const recapture = () => {
        // setIsReset(true);
        setImgSrc(null);
        props.setLivenessError(false);
    }

    // const videoConstraints = {
    //     width: 720,
    //     height: 1280,
    //     facingMode: "user"
    //   };

	return (
        
		<div className="wrap-webcam">

            {
                props.directSelfie === true ?
                <Fragment>
                {props.submitCount < props.settingLivenessLimit ?
                    <Fragment>
                    {
                        props.livenessError ?
                        <div className='liveness-error-wrapper'>
                        <h3><strong>Wajah Tidak Terdeteksi</strong></h3>
                        <p>Pastikan menggunakan objek asli, lepaskan aksesoris yang menutupi wajah (masker, kacamata hitam, topeng, dll), pencahayaan cukup, foto sendiri dan tidak ramai orang,pastikan wajah kamu berada di area frame atau tidak keluar frame, dan foto jelas tidak blur.</p>
                        </div>
                        : ''
                    }
        
                    {
                    imgSrc ? <div className='image-preview'><img src={imgSrc} alt=""/> {!props.livenessError ? <div className='liveness-processing'>Detecting liveness...</div> : ""}</div> 
                    :
                    <>
                    <div className='camera-container'>
                    <Webcam
                    style={{width: '100%'}}
                    mirrored={true}
                    audio={false}
                    screenshotFormat="image/png"
                    // onUserMediaError = { () => { console.log('handleCameraStop'); } }
                    // onUserMedia = { () => { console.log('handleUserMedia'); } }
                    ref={webcamRef}
                    // videoConstraints={videoConstraints}
                    className="webcam"
                />

      <div className="overlay">
        <svg className="circle" viewBox="0 0 340 375">
            <ellipse cx="50%" cy="50%" rx="48%" ry="48%" fill="transparent" stroke="white" strokeWidth="3" strokeDasharray="5 5" />
        </svg>
      </div>

                    </div>
                </>
                

                    }
        
                    {
                        imgSrc ? '' : <div className="navigate-webcam">
                        <ButtonCustom value={props.translate('btn_takephoto')} styleCustom="blue-btn" onButtonClick={capture} />
                        <ButtonCustom value={props.translate('btn_cancel')} styleCustom="yellow-btn" onButtonClick={props.handleClose} />
                    </div>
                    }
        
                    {
                        props.livenessError ? <div className="navigate-webcam"><ButtonCustom value="Foto Ulang" styleCustom="blue-btn" onButtonClick={recapture} /></div> : ''
                    }
                    </Fragment>
                    :
                    <div style={{padding: '15px', textAlign:'center'}}>
                        <p><strong>Mohon maaf. Sistem kami belum dapat melakukan proses registrasi. Harap hubungi CS kami di +628118198111</strong></p>
                        <ButtonCustom value="Tutup" styleCustom="yellow-btn" onButtonClick={props.handleClose} />
                        </div>
        
                }
                </Fragment>

                :
                <Fragment>
                <Webcam
                style={{width: '100%'}}
                mirrored={true}
                audio={false}
                screenshotFormat="image/png"
                // onUserMediaError = { () => { console.log('handleCameraStop'); } }
                // onUserMedia = { () => { console.log('handleUserMedia'); } }
                ref={webcamRef}
                />
                
                <div className="navigate-webcam">
                    <ButtonCustom value={props.translate('btn_takephoto')} styleCustom="blue-btn" onButtonClick={capture} />
                    <ButtonCustom value={props.translate('btn_cancel')} styleCustom="yellow-btn" onButtonClick={props.handleClose} />
                </div>
                </Fragment>

            }

        </div>
	)
}

// export default WebCamera;
export default props => (
    <RootContext.Consumer>
    {
        store => {
            return (
                <WebCamera {...props} translate={(value) => store.dispatch({type:'translate', 'text': value})} settingLivenessLimit={store.state.setting_liveness_limit} />
            )
        }
    }
    </RootContext.Consumer>
)