import React, {Fragment,useState} from 'react';
import { Formik } from 'formik';
import { Form, Col, Modal, Button as ReactBootstrapButton } from 'react-bootstrap';
import { RootContext } from '../Home';
import '../assets/sass/form.scss';
import validate from './validate';

import AnchorText from '../components/mini/AnchorTextCustom';
import style from './CitizenshipPage.module.scss';
import styleSubmitted from './SubmittedPage.module.scss';
import logo from '../assets/logo-korindo-icon.png';
import CustomInput from '../components/mini/CustomInput';
import Button from '../components/mini/ButtonCustom';
import Alert from '../components/mini/AlertCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCheck } from '@fortawesome/free-solid-svg-icons';
import './RegistrationTransition.scss';
import cx from 'classnames';

const months = [
	    "January",
	    "February",
	    "March",
	    "April",
	    "May",
	    "June",
	    "July",
	    "August",
	    "September",
	    "October",
	    "November",
	    "December"
	]

function dateFormat(paramDate) {
	let date = new Date(paramDate);
	return (date.getDate() + ' ' + months[(date).getMonth()] + ' ' + date.getFullYear());
}

const CitizenshipPage = (props) => {
	const [clickOTPUnset, setClickOTPUnset] = useState(false);
	const [showModalInfo, setShowModalInfo] = useState(true);
  	return (
		<RootContext.Consumer>
		{
			store => {
				if (store.state.foreignerSubmited) {
					return (
						<Fragment>
							<div className={ styleSubmitted['submitted-content'] }>
								<div className={ styleSubmitted['check-circle'] } >
									<FontAwesomeIcon icon={faCheck} />
								</div>

								<div className={ styleSubmitted['information'] }>
									<div className={ styleSubmitted['title'] }>
										{store.dispatch({type:'translate', 'text': 'request_success_title'})}
									</div>

									<div className={ styleSubmitted['description'] }>
										{store.dispatch({type:'translate', 'text': 'request_success_desc'})}
									</div>
								</div>
								<div className={ styleSubmitted['divider'] }>
									<img src={logo} className={styleSubmitted['img']} alt=""/>
								</div>
								<AnchorText text={store.dispatch({type:'translate', 'text': 'btn_back'})} className="back-anchor" to="/" />
								
							</div>
						</Fragment>
					)
				} else {
					return (
						<div className={(store.state.submitted.submitted === 1) ? cx(style['citizenship-content'], 'registration-status') : style['citizenship-content']}>
							<Formik
								validate={(values) => validate(values, '#citizenship', store.state.lang)}	
								// validationSchema={schema}
								onSubmit={(values, {setSubmitting,resetForm}) => {
							      	store.dispatch({type:'handleSubmit',field:'citizenship', values: values});
							      	// if (!store.state.redirectRegistration) {
							      	// 	setSubmitting(false);
							      	// }
								}}
								initialValues={{
									identitynumber: store.state.citizenship.identitynumber,
									fullname: store.state.citizenship.fullname,
									dob: store.state.citizenship.dob,
									foreigner: !props.citizenship ? false : true,
									otp: store.state.citizenship.otp,
									email: store.state.citizenship.email,
									phonenumber: store.state.citizenship.phonenumber,
									referral_code: store.state.user_id,
								}}
								>
								{({
									handleSubmit,
									handleChange,
									handleBlur,
									values,
									isSubmitting,
									touched,
									isValid,
									errors,
									setFieldValue,
									setFieldTouched,
									setSubmitting,
								}) => (

									<Fragment>
										<label className={style['title']}>{!props.citizenship ? store.dispatch({type:'translate', 'text': 'basic_info_title'}) : store.dispatch({type:'translate', 'text': 'label_wna'}) }</label>

										<Form id="citizenship" className="form" validated={errors ? false : true } noValidate onSubmit={handleSubmit}>
											{store.state.errors.global_message !== '' ? 
												<Alert 
													type={store.state.errors.global_type}
													message={
														store.dispatch({type:'translate', 'text': store.state.errors.global_message}) ? 
															store.dispatch({type:'translate', 'text': store.state.errors.global_message}) 
															: 
															typeof store.state.errors.global_message === 'object' ? 
																store.state.errors.global_message[store.state.lang]
																:
																store.state.errors.global_message
													}
												/> : ''
											}
											<input type="hidden" name="foreigner" value={values.foreigner} />
											{
                                            	/*
	                                            <Form.Row>
	                                                <pre
	                                                  className="col-lg-12"
	                                                  style={{
	                                                    background: '#f6f8fa',
	                                                    fontSize: '.65rem',
	                                                    padding: '.5rem',
	                                                    textAlign: 'left',
	                                                  }}
	                                                >
	                                                  <strong>props</strong> ={' '}
	                                                  {JSON.stringify(values, null, 2)}
	                                                  <br/>
	                                                  <strong>errors</strong> ={' '}
	                                                  {JSON.stringify(errors, null, 2)}
	                                                  <br/>
	                                                </pre>
	                                            </Form.Row>
	                                            */
	                                        }
	                                        {
	                                        	(store.state.submitted.submitted === 1) ?
	                                        	<Fragment>
													<div className="wrap-identity">
														<div className="column">
															<label>No. KTP</label>
															<span>{store.state.submitted.identitynumber}</span>
														</div>
														<div className="column">
															<label>{store.dispatch({type:'translate', 'text': 'fullname'})}</label>
															<span>{store.state.submitted.fullname}</span>
														</div>
														<div className="column">
															<label>{store.dispatch({type:'translate', 'text': 'dob'})}</label>
															<span>{dateFormat(store.state.submitted.dob)}</span>
														</div>
													</div>

													<div className="wrap-summary">
														<div className="step done">
															<div className="title">
									        					<FontAwesomeIcon icon={faCheck} className="icon" />
																Registration
															</div>
															<div className="link-download">
																<div className={(store.state.submitted.application_form_pdf_path !== null) ? "form-app-doclink" : "form-app-doclink process"}>
									        						<FontAwesomeIcon icon={faDownload} className="icon" />
									        						{
																		(store.state.submitted.application_form_pdf_path !== null) ? 
																		<a rel="noopener noreferrer" href={store.state.submitted.form_application_doclink} target="_blank">{store.dispatch({type:'translate', 'text': 'download_form_nh'})}</a>
																		:<Fragment><span className="not-yet">{store.dispatch({type:'translate', 'text': 'download_form_nh'})}</span> <span className="not-available">({store.dispatch({type:'translate', 'text': 'error_not_available'})})</span></Fragment>
																	}
																</div>
																<div className={(store.state.submitted.term_service_pdf_path !== null) ? "term-service-doclink" : "term-service-doclink process"}>
									        						<FontAwesomeIcon icon={faDownload} className="icon" />
																	{
																		(store.state.submitted.term_service_pdf_path !== null) ? 
																		<a rel="noopener noreferrer" href={store.state.submitted.term_service_doclink} target="_blank">{store.dispatch({type:'translate', 'text': 'download_tnc_nh'})}</a>
																		:<Fragment><span className="not-yet">{store.dispatch({type:'translate', 'text': 'download_tnc_nh'})}</span> <span className="not-available">({store.dispatch({type:'translate', 'text': 'error_not_available'})})</span></Fragment>
																	}
																</div>
															</div>
															<div className="info">
															{
																store.dispatch({type:'translate', 'text': store.state.errors.global_message}) ? 
																	store.dispatch({type:'translate', 'text': store.state.errors.global_message}) 
																	: 
																	typeof store.state.errors.global_message === 'object' ? 
																		store.state.errors.global_message[store.state.lang]
																		:
																		store.state.errors.global_message
															}
															</div>
														</div>
														<div className={(store.state.submitted.ksei_status === 'done' && store.state.submitted.ksei_sid) ? "step done" : "step"}>
															<div className="title">
									        					<FontAwesomeIcon icon={faCheck} className="icon" />
																KSEI Process
															</div>
															<div className="link-download">
																<div className={(store.state.submitted.ksei_sre) ? "sre" : "sre process"}>
									        						<FontAwesomeIcon icon={faCheck} className="icon" />
									        						<span>SRE (Sub Rekening Efek)</span>
																</div>
																<div className={(store.state.submitted.ksei_sid) ? "sid" : "sid process"}>
									        						<FontAwesomeIcon icon={faCheck} className="icon" />
									        						<span>SID (Single Investor Identification)</span>
																</div>
															</div>
														</div>
														<div className= {store.state.submitted.rdn_number ? "step done" : "step"}>
															<div className="title">
									        					<FontAwesomeIcon icon={faCheck} className="icon" />
																RDN
															</div>
														</div>
													</div>
		                                        </Fragment>
		                                        :
		                                        <Fragment>
												<Form.Row>
													<CustomInput 
														col="12"
														labelName={!props.citizenship ? "No. KTP" : 'KITAS'}
														required
														name="identitynumber"
														type="text"
													    minLength={!props.citizenship ? "16" : '10'}
							      						maxLength="16"
							      						pattern={!props.citizenship ? "^[0-9]*$" : "^[A-Z a-z 0-9*$]+"}
							      						info={!props.citizenship ? store.dispatch({type:'translate', 'text': 'idenhelp'}) : false}
							      						value={values.identitynumber}
							      						handleChange={handleChange}
													    isValid={touched.identitynumber && !errors.identitynumber}
													    isInvalid={!!errors.identitynumber}
													    error={(typeof errors.identitynumber === 'object') ? store.dispatch({type:'translate', 'text': errors.identitynumber.type}).toString().replace('$param$', errors.identitynumber.param).replace('$param2$', errors.identitynumber.param2) : store.dispatch({type:'translate', 'text': errors.identitynumber})}
													/>
												</Form.Row>

												<Form.Row>
													<CustomInput 
														col="6"
														labelName={store.dispatch({type:'translate', 'text': 'fullname'})}
														required
														name="fullname"
														type="text"
							      						maxLength="100"
							      						pattern="^[A-Z a-z.,'&quot;]+"
														info={!props.citizenship ? store.dispatch({type:'translate', 'text': 'idenhelp'}) : false}
							      						value={values.fullname}
							      						handleChange={(el) => {
															let elVal = el.target.value ? el.target.value.toUpperCase() : "";
															setFieldValue("fullname", elVal);
														}}
													    isValid={touched.fullname && !errors.fullname}
													    isInvalid={!!errors.fullname}
													    error={(typeof errors.fullname === 'object') ? store.dispatch({type:'translate', 'text': errors.fullname.type}).toString().replace('$param$', errors.fullname.param).replace('$param2$',errors.fullname.param2) : store.dispatch({type:'translate', 'text': errors.fullname})}
													/>

													<CustomInput 
														col="6"
														labelName={store.dispatch({type:'translate', 'text': 'dob'})}
														required
														name="dob"
														type="date"
							      						dateType="birthdate"
							      						value={values.dob}
														info={!props.citizenship ? store.dispatch({type:'translate', 'text': 'idenhelp'}) : false}														
							      						handleChange={date => setFieldValue('dob', date)}
													    isValid={touched.dob && !errors.dob}
													    isInvalid={!!errors.dob}
													    error={(typeof errors.dob === 'object') ? store.dispatch({type:'translate', 'text': errors.dob.type}).toString().replace('$param$', errors.dob.param).replace('$param2$',errors.dob.param2) : store.dispatch({type:'translate', 'text': errors.dob})}
													    placeholder="DD/MM/YYYY"
													/>
												</Form.Row>

												{
													props.citizenship ? 
														<Fragment>
															{!clickOTPUnset ? 
															<Form.Row>
																<CustomInput 
																	col="6"
																	labelName={store.dispatch({type:'translate', 'text': 'otp'})}
																	required
																	name="otp"
																	type="text"
										      						maxLength="8"
										      						minLength="8"
									      							pattern="^[0-9]*$"
										      						value={values.otp}
										      						handleChange={handleChange}
																    isValid={touched.otp && !errors.otp}
																    isInvalid={!!errors.otp}
																    error={(typeof errors.otp === 'object') ? store.dispatch({type:'translate', 'text': errors.otp.type}).toString().replace('$param$', errors.otp.param).replace('$param2$',errors.otp.param2) : store.dispatch({type:'translate', 'text': errors.otp})}
																/>
																<CustomInput
																	col="6"
																	labelName={store.dispatch({type:'translate', 'text': 'referral_code'})}
																	name="referral_code"
																	type="text"
										      						maxLength="100"
										      						pattern="^[0-9A-Za-z -_*]+"
										      						value={values.referral_code}
										      						handleChange={handleChange}
																    isValid={touched.referral_code && !errors.referral_code}
																    isInvalid={!!errors.referral_code}
																    error={(typeof errors.referral_code === 'object') ? store.dispatch({type:'translate', 'text': errors.referral_code.type}).toString().replace('$param$', errors.referral_code.param).replace('$param2$',errors.referral_code.param2) : store.dispatch({type:'translate', 'text': errors.referral_code})}
																    description={store.dispatch({type: 'translate', text: 'referral_code_description'})}
																    disabled={!store.state.user_id_editable}
																/>
															</Form.Row>
															:
															<Form.Row>
																<CustomInput 
																	col="6"
																	labelName={store.dispatch({type:'translate', 'text': 'email'})}
																	required
																	name="email"
																	type="email"
										      						maxLength="100"
										      						value={values.email}
										      						handleChange={handleChange}
																    isValid={touched.email && !errors.email}
																    isInvalid={!!errors.email}
																    error={(typeof errors.email === 'object') ? store.dispatch({type:'translate', 'text': errors.email.type}).toString().replace('$param$', errors.email.param).replace('$param2$',errors.email.param2) : store.dispatch({type:'translate', 'text': errors.email})}
																/>
																<CustomInput 
																	col="6"
																	labelName={store.dispatch({type:'translate', 'text': 'phone_number'})}
																	required
																	name="phonenumber"
																	type="inputmask"
										      						// maxLength="100"
									      							// pattern="^[0-9]*$"
									      							mask="+62 999 9999 9999"
									      							maskChar=" "
									      							handleKeyUp={(e)=>{
													                	if (e.target.value.charAt(4) === '0') {
													                		e.target.value = e.target.value.slice(0, 4) + e.target.value.slice(5).trim();
													                	}
									      							}}
										      						value={values.phonenumber}
										      						handleChange={handleChange}
																    isValid={touched.phonenumber && !errors.phonenumber}
																    isInvalid={!!errors.phonenumber}
																    error={(typeof errors.phonenumber === 'object') ? store.dispatch({type:'translate', 'text': errors.phonenumber.type}).toString().replace('$param$', errors.phonenumber.param).replace('$param2$',errors.phonenumber.param2) : store.dispatch({type:'translate', 'text': errors.phonenumber})}
																/>
															</Form.Row>}
										      				<Form.Row>
										      					<Col lg={12}>
																	<p className={ style['description'] }>
																		<AnchorText id="tag-req-otp" to="#" text={store.dispatch({type:'translate', 'text': 'click_here'})} onClickAnchorText={(value) => !clickOTPUnset ? setClickOTPUnset(true) : setClickOTPUnset(false)} /> { !clickOTPUnset ? store.dispatch({type:'translate', 'text': 'foreigner_1'}) : store.dispatch({type:'translate', 'text': 'foreigner_2'})}
																	</p>
										      					</Col>
															</Form.Row>
														</Fragment>
													: ''
												}

												{
													!props.citizenship ?
														<Fragment>
															<Form.Row>
																<CustomInput
																	col="6"
																	labelName={store.dispatch({type:'translate', 'text': 'referral_code'})}
																	name="referral_code"
																	type="text"
										      						maxLength="100"
										      						pattern="^[0-9A-Za-z -_*]+"
										      						value={values.referral_code}
										      						handleChange={handleChange}
																    isValid={touched.referral_code && !errors.referral_code}
																    isInvalid={!!errors.referral_code}
																    error={(typeof errors.referral_code === 'object') ? store.dispatch({type:'translate', 'text': errors.referral_code.type}).toString().replace('$param$', errors.referral_code.param).replace('$param2$',errors.referral_code.param2) : store.dispatch({type:'translate', 'text': errors.referral_code})}
																    description={store.dispatch({type: 'translate', text: 'referral_code_description'})}
																    disabled={!store.state.user_id_editable}
																/>
															</Form.Row>
														</Fragment>
														:
														''
												}
												</Fragment>
	                                        }



											<Form.Row>
												<Col lg={12}>
													{/* <AnchorText text={store.dispatch({type:'translate', 'text': 'link_back'})} className="back-anchor" to="/" /> */}
													{
														(store.state.submitted.submitted !== 1) ?
						     							<Button type="submit" value={store.dispatch({type:'translate', 'text': 'btn_submit'})} loading={store.state.process} disabled={store.state.process}></Button>
						     							: ''
													}
												</Col>
						     				</Form.Row>

											<Form.Row>
												<Col lg={12}>
													<div className={style['divider']}>
													<img src={logo} className={style['img']} alt=""/>
													</div>
													<div className={style['info']}>{store.dispatch({type:'translate', 'text': 'status_info'})}<a href="/status" rel="noopener noreferrer" style={{'fontWeight': 'bold','fontStyle': 'italic','color': '#0172ba','textDecoration': 'none','paddingBottom': '1px','borderBottom': '1px solid transparent','transition': 'all 0.5s'}}> {store.dispatch({type:'translate', 'text': 'state_here'})} </a>
													</div>
												</Col>
											</Form.Row>
										</Form>
									</Fragment>
								)}
							</Formik>
							
							{/*Modal Info*/}
							<div>
								<Modal show={showModalInfo} centered={true} contentClassName={style['homepageModalInfo']} size="lg">
									<Modal.Body>
										<div className={style['ModalBodyInner']}>
											<p>Bagi pengguna CIMB atau Pemilik Produk (CIF) CIMB. Harap memasukkan data yang sama saat melakukan pendaftaran CIMB. Kesalahan/ketidak cocokan data dapat memperlambat atau menghambat pembuatan akun.</p>
											<p>For CIMB users or CIMB Product Owners (CIFs). Please input the same data when registering for CIMB. Data errors/mismatches can slow down or prevent account creation.</p>
										</div>
									</Modal.Body>
									<div className={style['homepageModalInfoFooter']}>
										<ReactBootstrapButton variant="primary" onClick={() => {setShowModalInfo(false)}}>Lanjutkan / Continue</ReactBootstrapButton>
									</div>
								</Modal>
							</div>
						</div>
						
					)
				}
			}
		}
		</RootContext.Consumer>
  	)
}

export default CitizenshipPage;